import getMatch from "@/game-deadlock/fetches/match.mjs";
import getSteam from "@/game-deadlock/fetches/steam.mjs";
import { steam3to64 } from "@/util/steam.mjs";

export default async function fetchData([steamId, matchId]: FixedLengthArray<
  string,
  2
>): Promise<void> {
  await Promise.all([
    getSteam({
      steamId3: steamId,
      steamId64: steam3to64(Number(steamId)),
    }),
    (async (): Promise<void> => {
      const match = await getMatch({ matchId });
      if (!Array.isArray(match?.players)) return;
      // The data being fetched are below the fold in this page and can update async
      for (const { account_id: steamId3 } of match.players)
        getSteam({
          steamId3,
          steamId64: steam3to64(steamId3),
        });
    })(),
  ]);
}
